export const GOOGLE_MAPS_API_KEY = "AIzaSyAbBzAuFas9st0XPrFEf5587wRnJOk90GU";
export const MAP_TYPES = ["(regions)"];
export const ADDRESS_TYPE = ["address"];
export const MAP_COUNTRY = "au";
export const ReCAPTCHA_SITE_KEY = "6LevgTYnAAAAAA4M51qKTVJhxM1PEMwa9C-LRy8D";
export const SITE_URL = "https://www.hairhouse.com.au";
export const INPUT_MIN_CHAR = 3;
export const VISIBLE_STORES_COUNT = 3;
/**
 * 10 digit mobile number including two blank spaces
 * number format as: **** *** ***
 */
export const MOBILE_NUMBER_LENGTH = 12;

export const ROUTES = {
  SUCCESS: "/submitted-successfully",
  PIERCING_CONSENT: "/piercing-consent",
};

export const ERROR_MESSAGE = {
  STORE: "Please select a store",
  APPOINTMENT_DATE: "Please select an appointment date",
  NAME: "Please enter a valid name",
  EMAIL: "Please enter a valid email address",
  MOBILE: "Please enter a valid mobile number",
  INVALID_MOBILE:
    "Your phone number is invalid. Please ensure mobile number starts with 04.",
  RESIDENTIAL_ADDRESS: "Please enter residential address",
  DATE_OF_BIRTH: "Please enter date of birth",
  PIERCING_BODY_PARTS: "Atleast one body part must be selected",
  SELECT_BOOLEAN: "Please select an option",
  MEDICAITON_LIST: "Please enter medication list",
  NON_PRESCRIBED_DRUGS: "Please enter non prescribed drugs",
  CHECKLIST: "Please check all the boxes",
  SELECTED_LOCATION: "Please select a location",
  RECAPTCHA: "Please verify you are not a robot",
  PARENT_NAME: "Please enter a valid name",
  API_ERROR: "Oops! Something went wrong. Please try again later.",
};

// fields values
export const radioOptions = [
  { value: 2, label: "Yes" },
  { value: 1, label: "No" },
];

export const diseaseOptions = [
  [
    {
      value: "custrecord_hh_cpc_heartdisease",
      label: "Heart Disease",
      isChecked: false,
    },
    {
      value: "custrecord_hh_cpc_panicattacks",
      label: "Panic Attack",
      isChecked: false,
    },
  ],
  [
    {
      value: "custrecord_hh_cpc_diabetes",
      label: "Diabetes",
      isChecked: false,
    },
    {
      value: "custrecord_hh_cpc_epilepsy",
      label: "Epilepsy",
      isChecked: false,
    },
  ],
  [
    {
      value: "custrecord_hh_cpc_hepatitis",
      label: "Hepatitis",
      isChecked: false,
    },
    {
      value: "custrecord_hh_cpc_keloiding",
      label: "Keloiding",
      isChecked: false,
    },
  ],
  [
    { value: "custrecord_hh_cpc_hiv", label: "HIV", isChecked: false },
    {
      value: "custrecord_hh_cpc_allergies",
      label: "Allergies",
      isChecked: false,
    },
  ],
];

export const checklistOptions = [
  [
    {
      value: "custrecord_hh_cpc_precheck10",
      label: "Organise a parent/guardian to be present at your appointment",
      parentConsent: true,
      isChecked: false,
    },
    {
      value: "custrecord_hh_cpc_precheck11",
      label: "Parent/guardian must bring their legal ID",
      parentConsent: true,
      isChecked: false,
    },
    {
      value: "custrecord_hh_cpc_precheck12",
      label:
        "Parent/guardian must bring Medicare card with child listed or a birth certificate",
      parentConsent: true,
      isChecked: false,
    },
    {
      value: "custrecord_hh_cpc_precheck2",
      label: "Ensure you've eaten within 4 hours of your appointment",
      parentConsent: false,
      isChecked: false,
    },
    {
      value: "custrecord_hh_cpc_precheck3",
      label:
        "Ensure you don't drink alcohol within 8 hours of your appointment",
      parentConsent: false,
      isChecked: false,
    },
  ],
];

export const piercingOptions = [
  [
    { value: "1", label: "Ears", isChecked: false },
    { value: "5", label: "Nipple", isChecked: false },
  ],
  [
    { value: "2", label: "Nose", isChecked: false },
    { value: "3", label: "Face", isChecked: false },
  ],
  [
    { value: "102", label: "Naval", isChecked: false },
    { value: "4", label: "Lips", isChecked: false },
  ],
  [
    { value: "103", label: "Eyebrow", isChecked: false },
    { value: "101", label: "Dermal", isChecked: false },
  ],
];

export const stateInitialsMapper = {
  "western australia": "WA",
  "south australia": "SA",
  "new south wales": "NSW",
  "australian capital territory": "ACT",
  victoria: "VIC",
  queensland: "QLD",
};

export const piercingConsentMatrix = {
  earLobe: {
    WA: null,
    SA: { minAge: 16 },
    NSW: null,
    ACT: null,
    VIC: null,
    QLD: null,
  },
  // ears
  1: {
    WA: { minAge: 16 },
    SA: { minAge: 18 },
    NSW: { minAge: 16 },
    ACT: { minAge: 16 },
    VIC: { minAge: 16 },
    QLD: { minAge: 16 },
  },
  // nipple
  5: {
    WA: { minAge: 18 },
    SA: { minAge: 18 },
    NSW: { minAge: 16 },
    ACT: { minAge: 18 },
    VIC: { minAge: 18 },
    QLD: { minAge: 18 },
  },
  // nose
  2: {
    WA: { minAge: 18 },
    SA: { minAge: 18 },
    NSW: { minAge: 16 },
    ACT: { minAge: 16 },
    VIC: { minAge: 16 },
    QLD: { minAge: 16 },
  },
  // face
  3: {
    WA: { minAge: 18 },
    SA: { minAge: 18 },
    NSW: { minAge: 16 },
    ACT: { minAge: 16 },
    VIC: { minAge: 16 },
    QLD: { minAge: 16 },
  },
  // naval
  102: {
    WA: { minAge: 18 },
    SA: { minAge: 18 },
    NSW: { minAge: 16 },
    ACT: { minAge: 16 },
    VIC: { minAge: 16 },
    QLD: { minAge: 16 },
  },
  // lips
  4: {
    WA: { minAge: 18 },
    SA: { minAge: 18 },
    NSW: { minAge: 16 },
    ACT: { minAge: 16 },
    VIC: { minAge: 16 },
    QLD: { minAge: 16 },
  },
  // eyebrow
  103: {
    WA: { minAge: 18 },
    SA: { minAge: 18 },
    NSW: { minAge: 16 },
    ACT: { minAge: 16 },
    VIC: { minAge: 16 },
    QLD: { minAge: 16 },
  },
  // dermal
  101: {
    WA: { minAge: 18 },
    SA: { minAge: 18 },
    NSW: { minAge: 16 },
    ACT: { minAge: 16 },
    VIC: { minAge: 16 },
    QLD: { minAge: 16 },
  },
};
