import React, { useContext } from "react";
import {
  FormContainer,
  FormHeading,
  FormGroupSubmitted,
  FormGroupSubmittedPara,
  Appointment,
  AppointmentContainer,
  FlexAppointment,
  Star,
  AppointmentText,
  TakeMeShoppingButton,
  Link,
} from "./styles/form";
import { SITE_URL } from "./constant";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useEffect } from "react";
import { AppContext } from "./App";

const TakeMeShop = () => {
  const [appState] = useContext(AppContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <main>
        <FormContainer>
          <FormHeading>
            Piercing consent, <br /> waiver and release form.
          </FormHeading>
          <FormGroupSubmitted>
            Form submitted successfully!
            <FormGroupSubmittedPara>
              The details have been sent to your piercer ready for your
              appointment and will be confirmed prior to the service being
              performed.
            </FormGroupSubmittedPara>
            <AppointmentContainer>
              <Appointment>For your appointment, remember to:</Appointment>

              {appState.parentalConsentState && (
                <>
                  <FlexAppointment>
                    <Star>*</Star>
                    <AppointmentText>
                      Organise a parent/guardian to be present at your
                      appointment
                    </AppointmentText>
                  </FlexAppointment>
                  <FlexAppointment>
                    <Star>*</Star>
                    <AppointmentText>
                      Parent/guardian must bring their legal ID
                    </AppointmentText>
                  </FlexAppointment>
                  <FlexAppointment>
                    <Star>*</Star>
                    <AppointmentText>
                      Parent/guardian must bring Medicare card with child listed
                      or a birth certificate
                    </AppointmentText>
                  </FlexAppointment>
                </>
              )}

              <FlexAppointment>
                <Star>*</Star>
                <AppointmentText>
                  Ensure you've eaten within 4 hours of your appointment
                </AppointmentText>
              </FlexAppointment>
              <FlexAppointment>
                <Star>*</Star>
                <AppointmentText>
                  Ensure you don't drink alcohol within 8 hours of your
                  appointment
                </AppointmentText>
              </FlexAppointment>
            </AppointmentContainer>
          </FormGroupSubmitted>
          <TakeMeShoppingButton>
            <Link href={SITE_URL}>Take me shopping!</Link>
          </TakeMeShoppingButton>
        </FormContainer>
      </main>
      <Footer />
    </>
  );
};
export default TakeMeShop;
