import React from "react";
import { FlexDiv, Input, CheckboxLabel, FlexContainer } from "./styles/form";

const RadioGroup = ({ options, selectedOption, onOptionChange }) => (
  <FlexContainer>
    {options.map((option) => (
      <FlexDiv
        key={option.value}
        onClick={() => onOptionChange(option.value)}
        className={selectedOption === option.value ? "active" : ""}
      >
        <Input
          className="checkClass"
          type="checkbox"
          name="radio"
          value={option.value}
          checked={selectedOption === option.value}
          onChange={() => onOptionChange(option.value)}
        />
        <CheckboxLabel>{option.label}</CheckboxLabel>
      </FlexDiv>
    ))}
  </FlexContainer>
);

export default RadioGroup;
