import React from "react";
import {
  HeaderContainer,
  MainContainer,
  HeaderLogo,
  Link,
} from "../../styles/form";
import { SITE_URL } from "../../constant";
import headerLogo from "./../../image/HairhouseLogoHeader.png";

const Header = () => {
  return (
    <HeaderContainer>
      <MainContainer>
        <Link href={SITE_URL}>
          <HeaderLogo src={headerLogo} alt="Hairhouse" />
        </Link>
      </MainContainer>
    </HeaderContainer>
  );
};

export default Header;
