import styled from "styled-components";

export const FormContainersibbling = styled.div`
  max-width: 600px;
  margin: 0 auto;
  display: none;
  margin-bottom: 104px;

  &.show {
    display: grid;
  }

  @media (max-width: 768px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

export const FormContainerChild = styled.div`
  max-width: 600px;
  margin: 0 auto;
  display: none;

  &.show {
    display: grid;
  }

  @media (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

export const SearchBtn = styled.div`
  text-align: right;
  position: relative;
`;
export const Image = styled.img`
  position: absolute;
  cursor: pointer;
  top: 21px;
  right: 21px;
`;
export const IconeCurrentLocation = styled.img`
  width: 16px;
`;

export const AddressIcon = styled.img`
  height: 14.4px;
  width: 10.8px;
  // margin-left: 21px;
  margin-left: 5px;
  margin-right: 6px;
`;

export const FormContainer = styled.div`
  display: grid;
  max-width: 600px;
  margin: 0 auto;

  @media (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

export const FormHeading = styled.div`
  max-width: 444px;
  font-weight: 500;
  font-size: 42px;
  font-family: "Fakt";
  letter-spacing: -1px;
`;

export const Label = styled.label`
  margin-bottom: 10px;
  font-size: 42px;
  font-family: "Fakt Medium";
  letter-spacing: -1px;
`;
export const FormGroup = styled.div`
  letter-spacing: -1px;
  font-family: "Fakt";
  font-size: 26px;
  font-weight: 500;
  margin-top: 64px;
  margin-bottom: 32px;
`;

export const FormGroupSubmitted = styled.div`
  letter-spacing: -1px;
  font-family: "Fakt";
  font-size: 26px;
  font-weight: 500;
  margin-top: 46px;
`;
export const FormGroupLocation = styled.div`
  letter-spacing: -1px;
  font-family: "Fakt";
  font-size: 26px;
  font-weight: 500;
  margin-top: 56px;
  margin-bottom: 20px;
`;
export const FormGroupAppointment = styled.div`
  letter-spacing: -1px;
  font-family: "Fakt";
  font-size: 26px;
  font-weight: 500;
  margin-top: 56px;
  margin-bottom: 32px;
`;
export const YourPiercing = styled.div`
  letter-spacing: -1px;
  font-family: "Fakt";
  font-size: 26px;
  font-weight: 500;
  margin-top: 40px;
  margin-bottom: 32px;
`;
export const Para = styled.p`
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 24px;
  margin-top: 16px;
  letter-spacing: -0.85px;
  font-family: Fakt;
`;

export const FormGroupSubmittedPara = styled.p`
  font-size: 22px;
  font-weight: 300;
  margin-bottom: 46px;
  margin-top: 24px;
  letter-spacing: -0.85px;
  font-family: Fakt;
`;

export const ParaMedical = styled.p`
  font-size: 22px;
  font-weight: 300;
  margin-top: 0px;
  margin-bottom: 24px;
  letter-spacing: -0.85px;
  font-family: Fakt;
`;

export const Input = styled.input.attrs({ type: "checkbox" })`
  width: 30px;
  height: 30px;
  margin: 16px;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
    text-align: center;
    font-size: 24px;
    border-radius: 2px;
  }
`;
export const CheckboxLabel = styled.div`
  font-size: 18px;
  letter-spacing: 0;
  font-weight: 400;
  width: fit-content;
`;
export const FlexDiv = styled.div`
  cursor: pointer;
  display: flex;
  width: -webkit-fill-available;
  align-items: center;
  margin-bottom: 8px;
  border: 1px solid transparent;

  &.active {
    background: #f9f5f0;
    border: 1px solid #dcd2c4;
    border-radius: 4px;
  }
`;
export const FlexContainer = styled.div`
  display: flex;
  column-gap: 20px;
  justify-content: space-between;
  flex-direction: ${(props) => props.direction || "row"};

  @media (max-width: 768px) {
    display: block;
  }
`;
export const CurrentLocationCta = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  float: left;
  font-size: 11px;
  cursor: pointer;
  text-transform: uppercase;
  padding: 21px 30px 18px;
  letter-spacing: 1.83px;
  font-weight: 600;
  border: 1px solid #000000;
  margin-top: 32px;
  text-align: center;

  @media (max-width: 600px) {
    float: none;
  }
`;
export const LocationDiv = styled.div`
  border-radius: 4px;
  max-width: 288px;
  margin-top: 32px;
`;
export const InputText = styled.input`
  //   color: #949494;
  padding: 19px;
  margin: 6px 0px 0px 0px;
  font-size: 18px;
  border: 1px solid #dfdfdf;
  width: -webkit-fill-available;

  &.error {
    border: 1px solid #ff0000;
  }

  @media (max-width: 768px) {
    padding: 19px 18px 18px 19px;
  }
`;
export const InputLocation = styled.input`
  font-size: 20px;
  padding: 19px;
  margin-bottom: 20px;
  border: 1px solid #dfdfdf;
  width: -webkit-fill-available;
  &.error {
    border: 1px solid #ff0000;
  }
`;
export const LocationCheckBox = styled.div`
  display: flex;
  width: -webkit-fill-available;
  align-items: center;
  cursor: pointer;
  margin-bottom: 8px;
  border: 1px solid transparent;

  &.active {
    background: #f9f5f0;
    border: 1px solid #dcd2c4;
    border-radius: 4px;
  }
`;
export const CheckboxDistance = styled.div`
  font-size: 14px;
  margin-right: 19px;
  font-family: "Fakt";
`;
export const PhotographsConsent = styled.div`
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 24px;
  letter-spacing: 0;
  font-family: "Fakt";
`;
export const SeeMoreStore = styled.a`
  margin-top: 12px;
  color: #000000;
  font-family: "Fakt";
  text-decoration: underline;
  cursor: pointer;
  display: inline;

  &:hover {
    text-decoration: none;
  }
`;
export const FormTextLbel = styled.label`
  font-family: "Fakt";
  font-size: 12px;
  // margin-top: 32px;
  color: #5b5b5b;
  letter-spacing: 0.3px;
  margin-bottom: 6px;
`;
export const DateText = styled.input`
  color: #000000;
  font-size: 18px;
  padding: 20px 12px 16px 19px;
  max-width: 288px;
  width: -webkit-fill-available;
  background: #fff;
  border: 1px solid #dfdfdf;
  align-items: center;
  font-family: "Fakt";
  -webkit-appearance: none;

  &.error {
    border: 1px solid #ff0000;
  }
`;

export const FormButton = styled.button`
  width: 288px;
  padding: 19px 24px 16px 24px;
  background-color: #000000;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2px;
  margin-top: 24px;
  text-transform: uppercase;

  @media (max-width: 640px) {
    width: 100%;
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
  }
`;

export const FormLabel = styled.label`
  color: #5b5b5b;
  font-family: Fakt;
  font-size: 12px;
  letter-spacing: 0.3px;
`;
export const FormLabelMedications = styled.label`
  color: #5b5b5b;
  font-family: Fakt;
  font-size: 12px;
  letter-spacing: 0.3px;
  margin-top: 16px;
`;
export const FormDetails = styled.div`
  margin-bottom: 24px;
`;
export const ResidentialAdd = styled.div`
  margin-bottom: 0px;
`;
export const FormDetailsMedication = styled.div`
  margin-top: 16px;
`;

export const DateOfBirthSectionLeft = styled.div`
  display: flex;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const DateOfBirthSectionRight = styled.div`
  color: #9a4a4a;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  background: #f7edeb;
  padding: 16px 17px 16px 19px;
  font-family: "Fakt";
  text-align: center;

  @media (max-width: 768px) {
    margin-left: 0px;
    margin-top: 5px;
  }
`;

export const DateOfBirthInputText = styled.input`
  background: white;
  color: #000000;
  padding: 19px 66px 18px 19px;
  font-size: 18px;
  border: 1px solid #dfdfdf;
  width: -webkit-fill-available;
  margin-top: 6px;
  -webkit-appearance: none;
  font-family: "Fakt";
  &.error {
    border: 1px solid #ff0000;
  }
`;

export const DateOfBirthMain = styled.div`
  @media (max-width: 768px) {
    display: block;
  }
`;

export const Error = styled.div`
  color: red;
  padding-top: 6px;
  font-size: 14px;
  max-width: 600px;
`;

export const MandatoryFieldIndicator = () => (
  <span style={{ color: "#B24B4B" }}>*</span>
);
export const Circle = styled.div`
  width: 27px;
  height: 27px;
  border-radius: 50%;
  border: 2px solid #b24b4b;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ExclamationMark = styled.span`
  font-size: 27px;
  color: #b24b4b;
`;
export const DateOfBirthFlex = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15px;

  @media (max-width: 768px) {
    margin-right: 0px;
  }
`;
export const CircleFlex = styled.div`
  position: absolute;
  right: 13px;
  top: 15px;
`;

export const Appointment = styled.p`
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 23px;
  margin-top: 25px;
  margin-left: 32px;
  margin-right: 32px;
  letter-spacing: -0.85px;
  font-family: Fakt;

  @media (max-width: 640px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

export const AppointmentContainer = styled.div`
  background-color: #f9f5f0;
  display: grid;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 22px;
`;

export const FlexAppointment = styled.div`
  display: flex;
  margin: 0 32px;
  @media (max-width: 640px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

export const AppointmentText = styled.div`
  font-family: Fakt;
  font-size: 20px;
  letter-spacing: 0;
  font-weight: 400;
  margin-bottom: 20px;
`;
export const Star = styled.div`
  margin-right: 20px;
`;

export const TakeMeShoppingButton = styled.span`
  width: 288px;
  margin-top: 46px;
  display: inline-block;

  a {
    color: #fff;
    padding: 19px 24px 16px 24px;
    background-color: #000000;
    border: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
    display: block;
  }

  @media (max-width: 640px) {
    width: 100%;
    box-sizing: border-box;
    display: block;
  }
`;
export const RecaptchaSection = styled.div`
  margin-top: 56px;
`;

export const FooterDiv = styled.div`
  font-family: "Fakt";
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  padding-left: 5px;
`;
export const FooterLogo = styled.img`
  margin-bottom: 50px;
`;

export const FooterYear = styled.div`
  margin-top: 50px;
  color: #666666;
  font-family: Fakt;
  font-size: 12px;
  letter-spacing: 10px;
  line-height: 12px;
`;
export const Divider = styled.div`
  width: auto;
  border: 1px solid #f3f3f3;
  margin-top: 120px;
`;

export const EmailDiv = styled.div`
  display: flex;
  font-weight: 400;
`;

export const HeaderLogo = styled.img`
  max-width: 276px;
`;

export const MainContainer = styled.div`
  max-width: 875px;
  margin: 0 auto;

  @media (max-width: 991px) {
    padding: 0 20px;
    max-width: 100%;
  }
`;

export const HeaderContainer = styled.header`
  border-bottom: 2px solid #f3f3f3;
  padding: 25px 0;
`;

export const FooterContainer = styled.footer`
  margin-top: auto;
  border-top: 2px solid #f3f3f3;
  padding: 50px 0 73px;
  height: auto;
`;

export const Link = styled.a`
  text-decoration: none;
  display: inline-block;
`;

export const DateWrapper = styled.div`
  width: 247px;
  justify-content: space-between;
  margin-top: 6px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const AppointmentWrapper = styled.div`
  position: relative;
  width: 320px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
