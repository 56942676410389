import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PiercingForm from "./PiercingForm";
import Home from "./Home";
import SubmittedSuccessfullyForm from "./submittedSuccessfully";
import { ROUTES } from "./constant";

export const AppContext = React.createContext();

const App = () => {
  const [appState, setAppState] = useState({});

  return (
    <AppContext.Provider value={[appState, setAppState]}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path={ROUTES.PIERCING_CONSENT} element={<PiercingForm />} />
          <Route
            path={ROUTES.SUCCESS}
            element={<SubmittedSuccessfullyForm />}
          />
        </Routes>
      </Router>
    </AppContext.Provider>
  );
};

export default App;
