import React, { useState } from "react";
import { FlexDiv, Input, CheckboxLabel, FlexContainer } from "./styles/form";

const CheckboxGroup = ({ options, onSelections, direction }) => {
  const handleCheckboxToggle = (value) => {
    onSelections(value);
  };

  return (
    <>
      {options.map((group, index) => (
        <FlexContainer direction={direction} key={index}>
          {group.map((option) => {
            return (
              <FlexDiv
                key={option.value}
                onClick={() => handleCheckboxToggle(option.value)}
                className={option.isChecked ? "active" : ""}
              >
                <Input
                  className="checkClass"
                  type="checkbox"
                  name="radio"
                  value={option.value}
                  checked={option.isChecked}
                  onChange={() => handleCheckboxToggle(option.value)}
                />
                <CheckboxLabel>{option.label}</CheckboxLabel>
              </FlexDiv>
            );
          })}
        </FlexContainer>
      ))}
    </>
  );
};

export default CheckboxGroup;
