import { piercingConsentMatrix, stateInitialsMapper } from "./constant";
import moment from "moment";

const REGEX = {
  EMAIL: /\S+@\S+\.\S+/,
  MOBILE: /^[0-9]{10}$/,
  MNUMBERS_ONLY: /^[0-9\b]+$/,
  NON_NUMERIC: /\D/g,
};

export const validateEmail = (value) => {
  return REGEX.EMAIL.test(value);
};

export const validateMobile = (mobileNumber) => {
  const startNumber = "04";
  return mobileNumber.startsWith(startNumber);
};

const isAgeGreaterThanMinimumAge = (selectedDate, currentDate) => {
  const timeDiff = Math.abs(currentDate.getTime() - selectedDate.getTime());
  const ageInYears = Math.floor(timeDiff / (1000 * 3600 * 24 * 365));
  const minimumAge = 16;
  if (ageInYears < minimumAge) {
    return false;
  }

  return true;
};

export const validateDateOfBirth = (dateOfBirth) => {
  const currentDate = new Date();
  const selectedDate = new Date(dateOfBirth);
  if (isNaN(selectedDate)) {
    return false;
  }
  return isAgeGreaterThanMinimumAge(selectedDate, currentDate);
};

export const validateNumbersOnly = (value) => {
  return REGEX.MNUMBERS_ONLY.test(value);
};

export const isObjectBlank = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

// create menthod to check if provided value is a blank array or not and also value should not be another other data type except array
export const isArrayBlank = (arr) => {
  return Array.isArray(arr) && arr.length === 0;
};

// check is provided value is an array or not
export const isArray = (arr) => {
  return Array.isArray(arr);
};

export const filterRequestObject = (obj) => {
  const newObj = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value !== null && value !== "") {
      newObj[key] = value;
    }
  }

  return newObj;
};

export const isParentalConsentRequired = (state, bodyPart, age) => {
  const minAge = piercingConsentMatrix[bodyPart][state]?.minAge;
  return minAge !== undefined ? age < minAge : false;
};

export const getAge = (dateOfBirth, appointmentDate) => {
  const selectedDate = moment(dateOfBirth);
  const selectedAppointmentDate = moment(appointmentDate);
  const ageDuration = moment.duration(
    selectedAppointmentDate.diff(selectedDate)
  );
  const ageInYears = ageDuration.years();

  return ageInYears;
};

export const getStateInitial = (state) => {
  return stateInitialsMapper[state.toLowerCase()];
};

export const formatDate = (date) => {
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObj.getFullYear();
  return `${day}/${month}/${year}`;
};

export const validateNipplePiercingSelection = (updatedOptions) => {
  return updatedOptions
    .flat()
    .some((item) => item.value === "5" && item.isChecked === true);
};
