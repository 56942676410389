import React from "react";
import { ErrorWrapper } from "./styles.js";

const ErrorNotification = ({ message, onClose }) => {
  return (
    <ErrorWrapper className={message ? "active" : ""}>
      <p>{message}</p>
      <button className="close-cta" onClick={onClose}>
        &times;
      </button>
    </ErrorWrapper>
  );
};

export default ErrorNotification;
