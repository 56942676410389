import React, { useEffect } from "react";

// redirect to https://www.hairhouse.com.au on page load
// return message as: "redirecting to hairhouse.com.au"
// horizontal and vertical center the message, no bootstrap or other css frameworks
// use react router to redirect to https://www.hairhouse.com.au

const Home = () => {
  useEffect(() => {
    window.location.href = "https://www.hairhouse.com.au";
  }, []);

  return (
    <div className="redirection-message">Redirecting to hairhouse.com.au</div>
  );
};

export default Home;
