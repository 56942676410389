import React, { useRef, useState, useEffect, useContext } from "react";
import iconCurrentLoc from "./image/CurrentLocation@2x.png";
import addIcon from "./image/Address@2x.png";
import { usePlacesWidget } from "react-google-autocomplete";
import CheckboxGroup from "./CheckboxGroup";
import RadioGroup from "./RadioGroup";
import ReCaptcha from "react-google-recaptcha";
import image from "./image/searchbtn.png";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "./constant";

import Header from "./components/Header/index.js";
import Footer from "./components/Footer/index.js";

import calender from "./image/calender.png";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  FormContainersibbling,
  FormContainerChild,
  FormContainer,
  FormHeading,
  Label,
  FormGroup,
  FormGroupLocation,
  FormGroupAppointment,
  YourPiercing,
  Para,
  ParaMedical,
  Input,
  SearchBtn,
  Image,
  AddressIcon,
  IconeCurrentLocation,
  CheckboxLabel,
  CurrentLocationCta,
  InputText,
  InputLocation,
  LocationCheckBox,
  CheckboxDistance,
  PhotographsConsent,
  SeeMoreStore,
  FormTextLbel,
  FormButton,
  FormLabel,
  FormDetails,
  FormDetailsMedication,
  DateOfBirthSectionLeft,
  DateOfBirthSectionRight,
  DateOfBirthMain,
  Error,
  MandatoryFieldIndicator,
  Circle,
  ExclamationMark,
  DateOfBirthFlex,
  CircleFlex,
  RecaptchaSection,
  DateWrapper,
  AppointmentWrapper,
} from "./styles/form";
import { getStoresList, requestFormSubmit } from "./actions";
import {
  GOOGLE_MAPS_API_KEY,
  MAP_TYPES,
  MAP_COUNTRY,
  radioOptions,
  piercingOptions as piercingOptionsList,
  diseaseOptions as diseaseOptionsList,
  checklistOptions,
  VISIBLE_STORES_COUNT,
  ERROR_MESSAGE,
  ReCAPTCHA_SITE_KEY,
  INPUT_MIN_CHAR,
  MOBILE_NUMBER_LENGTH,
} from "./constant";

import {
  validateEmail,
  validateMobile,
  validateDateOfBirth,
  isObjectBlank,
  filterRequestObject,
  getAge,
  isParentalConsentRequired,
  getStateInitial,
  isArrayBlank,
  isArray,
  formatDate,
  validateNipplePiercingSelection,
} from "./utils";
import ErrorNotification from "./components/ErrorNotification";
import { AppContext } from "./App";

const CheckboxForm = () => {
  // TO NAVIGATE TO NEXT PAGE
  const navigate = useNavigate();

  // Date Picker
  const onDateChangeHandler = (date) => {
    setAppointmentDate(date);
    handleInputChange({
      target: {
        name: "appointmentDate",
        value: date,
      },
    });
  };

  const onDateOfBirthChangeHandler = (date) => {
    setDateOfBirth(date);
    handleInputChange({
      target: {
        name: "dateOfBirth",
        value: date,
      },
    });
  };

  // INPUT FIELDS
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [parentName, setParentName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [residentialAddress, setResidentialAddress] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [medications, setMedications] = useState("");
  const [nonPrescribedDrugs, setNonPrescribedDrugs] = useState("");
  const [isParentalConsent, setIsParentalConsent] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [activeChecklist, setActiveChecklist] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFindLocation, setIsFindLocation] = useState(false);
  const [isWesternAustralia, setIsWesternAustralia] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [diseaseOptions, setDiseaseOptions] = useState(diseaseOptionsList);
  const [piercingOptions, setPiercingOptions] = useState(piercingOptionsList);
  const [isNipplePiercing, setIsNipplePiercing] = useState(false);

  // GLOBAL ERRORS
  const [errors, setErrors] = useState({});

  // STORE LOCATOR STATES
  const [locationData, setLocationData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isFetchingLocation, setIsFetchingLocation] = useState(false);
  const [isLocationError, setIsLocationError] = useState(false);
  const [storesVisibilityCount, setStoresVisibilityCount] =
    useState(VISIBLE_STORES_COUNT);
  const [dirtyFields, setDirtyFields] = useState([]);

  //recaptcha
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const captchaRef = useRef();

  // context
  const [appState, setAppState] = useContext(AppContext);

  // PRAGNANCY
  const [isPregnant, setIsPregnant] = useState(null);
  const handlePregnancyOptionChange = (selectedValue) => {
    setIsPregnant(selectedValue);
  };

  // NIPPLE PIERCING
  const [consentToOppositSexPiercer, setConsentToOppositSexPiercer] =
    useState(null);
  const handleConsentToOppositSexPiercerSelection = (selectedValue) => {
    if (selectedValue === 2) {
      setRequiredSupportPerson(null);
    }

    setConsentToOppositSexPiercer(selectedValue);
  };

  // SUPPORT PERSON
  const [requiredSupportPerson, setRequiredSupportPerson] = useState(null);
  const handleRequiredSupportPersonSelection = (selectedValue) => {
    setRequiredSupportPerson(selectedValue);
  };

  // FEAR OF MEDICAL PROCEDURE
  const [fearOfMedicalProcedures, setFearOfMedicalProcedures] = useState(null);
  const handleFearOfMedicalProceduresSelection = (selectedValue) => {
    setFearOfMedicalProcedures(selectedValue);

    validateInputesOnChange({
      name: "fearOfMedicalProcedures",
      value: selectedValue,
    });
  };

  // TAKING MEDICATION
  const [takingMedication, setTakingMedication] = useState(null);
  const handleTakingMedicationSelection = (selectedValue) => {
    setTakingMedication(selectedValue);
    validateInputesOnChange({
      name: "takingMedication",
      value: selectedValue,
    });
  };

  // TAKING NO PRESCRIPTION DRUGS
  const [takingNonPrescribedDrugs, setTakingNonPrescribedDrugs] =
    useState(null);
  const handleTakingNonPrescribedDrugsSelection = (selectedValue) => {
    setTakingNonPrescribedDrugs(selectedValue);

    validateInputesOnChange({
      name: "takingNonPrescribedDrugs",
      value: selectedValue,
    });
  };

  // PHOTOGRAPHY CONSENT
  const [phographyConsent, setPhographyConsent] = useState(null);
  const handlePhographyConsentSelection = (selectedValue) => {
    setPhographyConsent(selectedValue);

    validateInputesOnChange({
      name: "phographyConsent",
      value: selectedValue,
    });
  };

  // PIERCING OPTIONS
  const [piercingBodyParts, setPiercingBodyParts] = useState([]);
  const handlePiercingBodyPartsSelection = (selectedValue) => {
    if (!isNipplePiercing) {
      setConsentToOppositSexPiercer(null);
      setRequiredSupportPerson(null);
    }

    if (piercingBodyParts.includes(selectedValue)) {
      setPiercingBodyParts(
        piercingBodyParts.filter((item) => item !== selectedValue)
      );
    } else {
      setPiercingBodyParts([...piercingBodyParts, selectedValue]);
    }

    validateInputesOnChange({
      name: "piercingBodyParts",
      value: selectedValue,
    });

    const updatedPiercingOptions = piercingOptions.map((item) => {
      return item.map((option) => {
        return {
          ...option,
          ...(option.value === selectedValue && {
            isChecked: !option.isChecked,
          }),
        };
      });
    });

    const status = validateNipplePiercingSelection(updatedPiercingOptions);
    setIsNipplePiercing(status);

    setPiercingOptions(updatedPiercingOptions);
  };

  useEffect(() => {
    const parentalConsentState = validatePerantalConsent();
    setIsParentalConsent(parentalConsentState);

    setAppState({
      ...appState,
      parentalConsentState,
    });

    const overAgeChecklist = [
      checklistOptions[0].filter((item) => !item.parentConsent),
    ];

    setActiveChecklist(
      parentalConsentState ? checklistOptions : overAgeChecklist
    );
  }, [selectedLocation, dateOfBirth, piercingBodyParts]);

  useEffect(() => {
    const existingErrors = Object.keys(errors);
    setDirtyFields(isSubmitted ? existingErrors : dirtyFields);
    setIsSubmitted(false);
  }, [isSubmitted]);

  useEffect(() => {
    if (dirtyFields.length > 0) {
      handleValidations();
    }
  }, [dirtyFields]);

  useEffect(() => {
    if (isSubmitted) {
      scrollToError();
    }
  }, [errors, isSubmitted]);

  useEffect(() => {
    if (apiError) {
      setTimeout(() => {
        setApiError(null);
      }, 3000);
    }
  }, [apiError]);

  const scrollToError = () => {
    const errorElement = document.querySelector(".error");

    if (errorElement) {
      errorElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const validatePerantalConsent = () => {
    if (
      selectedLocation !== null &&
      dateOfBirth !== "" &&
      piercingBodyParts.length > 0
    ) {
      const selectedState = locationData.filter(
        (item) => item.location === selectedLocation
      )[0].state;
      const age = getAge(dateOfBirth, appointmentDate);

      const state = getStateInitial(selectedState);
      setIsWesternAustralia(state === "WA");

      const validatePerantalConsent = piercingBodyParts
        .map((item) => isParentalConsentRequired(state, item, age))
        .includes(true);

      return validatePerantalConsent;
    }
    return false;
  };

  // DISEASE OPTIONS
  const [currentDiseases, setCurrentDiseases] = useState([]);
  const handleCurrentDiseasesSelection = (selectedValue) => {
    const updatedCurrentDeseasesOptions = diseaseOptions.map((item) => {
      return item.map((option) => {
        return {
          ...option,
          ...(option.value === selectedValue && {
            isChecked: !option.isChecked,
          }),
        };
      });
    });

    setDiseaseOptions(updatedCurrentDeseasesOptions);

    if (currentDiseases.includes(selectedValue)) {
      setCurrentDiseases(
        currentDiseases.filter((item) => item !== selectedValue)
      );
    } else {
      setCurrentDiseases([...currentDiseases, selectedValue]);
    }
  };

  // CHECKLIST OPTIONS
  const handleChecklistSelection = (selectedValue) => {
    const updatedList = activeChecklist[0].map((item) => {
      return {
        ...item,
        ...(item.value === selectedValue && {
          isChecked: !item.isChecked,
        }),
      };
    });

    setActiveChecklist([updatedList]);

    validateInputesOnChange({
      name: "checklist",
      value: selectedValue,
    });
  };

  // STORE LOCATOR
  const handleCurrentLocation = async () => {
    try {
      setLocationData([]);
      setIsFetchingLocation(true);
      setIsLocationError(false);
      navigator.geolocation.getCurrentPosition(async (res) => {
        const stores = await getStoresList({
          lng: res.coords.longitude, // 144.963937
          lat: res.coords.latitude, // -37.8152065
        });

        setIsFetchingLocation(false);
        setIsLocationError(false);
        resetAndSetStores(stores);
      });
    } catch (error) {
      setIsFetchingLocation(false);
    }
  };

  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_MAPS_API_KEY,
    onPlaceSelected: async (place) => {
      setIsLocationError(false);
      setLocationData([]);
      setIsFindLocation(true);
      const stores = await getStoresList({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      setIsFindLocation(false);
      resetAndSetStores(stores);
    },
    options: {
      types: MAP_TYPES,
      componentRestrictions: {
        country: MAP_COUNTRY,
      },
    },
  });

  const resetAndSetStores = (stores) => {
    setSelectedLocation(null);
    setStoresVisibilityCount(VISIBLE_STORES_COUNT);

    if (stores && stores.length > 0) {
      setLocationData(stores);
    } else {
      setIsLocationError("no stores available...");
    }
  };

  const handleSelectLocation = (locationId) => {
    setSelectedLocation(locationId);
    validateInputesOnChange({
      name: "selectedLocation",
      value: locationId,
    });
  };

  const handleShowMore = () => {
    const newCount = storesVisibilityCount * 2;
    setStoresVisibilityCount(newCount);
  };

  const handlePhoneNumberInput = (input) => {
    const maxLength = 10;
    const spacePositions = [4, 7];
    const numericInput = input.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedInput = "";

    const numericInputLength = numericInput.length;

    if (numericInputLength > maxLength) {
      return;
    }

    for (let i = 0; i < numericInputLength; i++) {
      if (spacePositions.indexOf(i) >= 0) {
        formattedInput += " ";
      }
      formattedInput += numericInput[i];
    }

    setMobileNumber(formattedInput);
  };

  const handleInputChange = ({ target: { name, value } }) => {
    if (name === "name") {
      setName(value);
    }

    if (name === "parentName") {
      setParentName(value);
    }

    if (name === "email") {
      setEmail(value);
    }

    if (name === "mobileNumber") {
      handlePhoneNumberInput(value);
    }

    if (name === "appointmentDate") {
      setAppointmentDate(value);
    }

    if (name === "dateOfBirth") {
      setDateOfBirth(value);
    }

    if (name === "residentialAddress") {
      setResidentialAddress(value);
    }

    if (name === "medications") {
      setMedications(value);
    }

    if (name === "nonPrescribedDrugs") {
      setNonPrescribedDrugs(value);
    }

    validateInputesOnChange({ name, value });
  };

  const validateInputesOnChange = ({ name, value }) => {
    let isFieldDirty = value !== "" && value !== null && value !== undefined;
    let updatedDirtyFields = [...dirtyFields];

    // check for multiple values selection
    if (isArray(value)) {
      if (isArrayBlank(value)) {
        isFieldDirty = true;
      } else {
        isFieldDirty = false;
      }

      if (name === "checklist" && value.length < activeChecklist) {
        isFieldDirty = true;
      } else {
        isFieldDirty = false;
      }
    }

    if (isFieldDirty) {
      if (!updatedDirtyFields.includes(name)) {
        updatedDirtyFields.push(name);
      }
    } else {
      updatedDirtyFields = updatedDirtyFields.filter((item) => item !== name);
    }

    setDirtyFields(updatedDirtyFields);
  };

  const handleValidations = () => {
    const errors = {};

    if (selectedLocation == null) {
      errors.selectedLocation = ERROR_MESSAGE.SELECTED_LOCATION;
    }

    if (appointmentDate === "") {
      errors.appointmentDate = ERROR_MESSAGE.APPOINTMENT_DATE;
    }

    if (!validateEmail(email)) {
      errors.email = ERROR_MESSAGE.EMAIL;
    }

    if (mobileNumber.length < MOBILE_NUMBER_LENGTH) {
      errors.mobileNumber = ERROR_MESSAGE.MOBILE;
    }

    if (!validateMobile(mobileNumber)) {
      errors.mobileNumber = ERROR_MESSAGE.INVALID_MOBILE;
    }

    if (!validateDateOfBirth(dateOfBirth)) {
      errors.dateOfBirth = true;
    }

    if (name.length < INPUT_MIN_CHAR) {
      errors.name = ERROR_MESSAGE.NAME;
    }

    if (isParentalConsent && parentName.length < INPUT_MIN_CHAR) {
      errors.parentName = ERROR_MESSAGE.PARENT_NAME;
    }

    if (residentialAddress.length < INPUT_MIN_CHAR) {
      errors.residentialAddress = ERROR_MESSAGE.RESIDENTIAL_ADDRESS;
    }

    if (dateOfBirth === "") {
      errors.dateOfBirth = ERROR_MESSAGE.DATE_OF_BIRTH;
    }

    if (piercingBodyParts.length < 1) {
      errors.piercingBodyParts = ERROR_MESSAGE.PIERCING_BODY_PARTS;
    }

    if (fearOfMedicalProcedures == null) {
      errors.fearOfMedicalProcedures = ERROR_MESSAGE.SELECT_BOOLEAN;
    }

    if (takingMedication == null) {
      errors.takingMedication = ERROR_MESSAGE.SELECT_BOOLEAN;
    }

    if (takingMedication === 2 && medications.length < INPUT_MIN_CHAR) {
      errors.medications = ERROR_MESSAGE.MEDICAITON_LIST;
    }

    if (isNipplePiercing && consentToOppositSexPiercer === null) {
      errors.consentToOppositSexPiercer = ERROR_MESSAGE.SELECT_BOOLEAN;
    }

    if (consentToOppositSexPiercer === 2 && requiredSupportPerson === null) {
      errors.requiredSupportPerson = ERROR_MESSAGE.SELECT_BOOLEAN;
    }

    if (takingNonPrescribedDrugs == null) {
      errors.takingNonPrescribedDrugs = ERROR_MESSAGE.SELECT_BOOLEAN;
    }

    if (
      takingNonPrescribedDrugs === 2 &&
      nonPrescribedDrugs.length < INPUT_MIN_CHAR
    ) {
      errors.nonPrescribedDrugs = ERROR_MESSAGE.NON_PRESCRIBED_DRUGS;
    }

    if (phographyConsent == null) {
      errors.phographyConsent = ERROR_MESSAGE.SELECT_BOOLEAN;
    }

    if (activeChecklist.length > 0) {
      activeChecklist.flat().forEach((item) => {
        if (!item.isChecked) {
          errors.checklist = ERROR_MESSAGE.CHECKLIST;
        }
      });
    }

    if (recaptchaValue === "") {
      errors.recaptchaValue = ERROR_MESSAGE.RECAPTCHA;
    }

    const dirtyFieldsErrors = Object.keys(errors)
      .filter((item) => dirtyFields.includes(item))
      .reduce((obj, key) => {
        obj[key] = errors[key];
        return obj;
      }, {});

    setErrors(dirtyFieldsErrors);

    return errors;
  };

  // Prevent form submission on ENTER key press
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const resetCaptcha = () => {
    captchaRef.current.reset();
    setRecaptchaValue("");
  };

  const handleSubmit = async (event) => {
    setIsSubmitted(true);
    event.preventDefault();

    setErrors({}); // reset errors
    const errors = handleValidations();
    setErrors(errors);

    /**
     * exclude "errors.dateOfBirth === true" for submit form
     * it's only required for parental consent validation
     */
    if (errors.dateOfBirth === true) {
      delete errors.dateOfBirth;
    }

    if (isObjectBlank(errors)) {
      const requestObj = filterRequestObject({
        email,
        name,
        mobileNumber,
        residentialAddress,
        medications,
        nonPrescribedDrugs,
        selectedLocation,
        isPregnant,
        consentToOppositSexPiercer,
        requiredSupportPerson,
        fearOfMedicalProcedures,
        takingMedication,
        takingNonPrescribedDrugs,
        phographyConsent,
        piercingBodyParts,
        currentDiseases,
        parentName,
        appointmentDate: formatDate(appointmentDate),
        dateOfBirth: formatDate(dateOfBirth),
      });

      setIsLoading(true);
      try {
        const formRequest = await requestFormSubmit(requestObj);
        if (formRequest.success) {
          navigate(ROUTES.SUCCESS);
        }
      } catch (error) {
        resetCaptcha();
        setApiError(error.message);
      }

      setIsLoading(false);
    } else {
      resetCaptcha();
      setApiError(ERROR_MESSAGE.API_ERROR);
    }
  };

  const onChangeFunction = () => {
    setRecaptchaValue(true);
    validateInputesOnChange({
      name: "recaptchaValue",
      value: true,
    });
  };

  const handleErrorClose = () => {
    setApiError(null);
  };

  return (
    <>
      <Header />
      <main>
        <form onSubmit={handleSubmit} onKeyDown={handleKeyDown} noValidate>
          <FormContainer>
            <FormHeading>
              <Label>
                Piercing consent, <br></br> waiver and release form.
              </Label>
            </FormHeading>

            <FormGroup>Pregnancy</FormGroup>
            <Para>
              Are you currently pregnant? <MandatoryFieldIndicator />
            </Para>
            <RadioGroup
              options={radioOptions}
              selectedOption={isPregnant}
              onOptionChange={handlePregnancyOptionChange}
            />
          </FormContainer>

          <FormContainersibbling
            className={`form-container-child ${isPregnant === 2 ? "show" : ""}`}
          >
            Due to potential health risks, we are unable perform piercings if
            you are pregnant. Please refer to your GP for further advice.
          </FormContainersibbling>

          <FormContainerChild className={isPregnant === 1 ? "show" : ""}>
            <FormGroupLocation>
              Confirm location your piercing will be performed
              <CurrentLocationCta onClick={handleCurrentLocation}>
                <IconeCurrentLocation
                  src={iconCurrentLoc}
                ></IconeCurrentLocation>
                {isFetchingLocation ? "fetching..." : "Use Current Location"}
              </CurrentLocationCta>
            </FormGroupLocation>
            <SearchBtn>
              <InputLocation
                placeholder="Enter the suburb / postcode..."
                ref={ref}
                className={errors.selectedLocation ? "error" : ""}
              />
              {isFindLocation ? (
                <span className="loader location-loader"></span>
              ) : (
                <Image className="searchButton" src={image} />
              )}
            </SearchBtn>

            {locationData.length > 0 && (
              <>
                {locationData.map((item, index) => {
                  return (
                    index < storesVisibilityCount && (
                      <LocationCheckBox
                        key={item.location}
                        onClick={() => handleSelectLocation(item.location)}
                        className={
                          item.location === selectedLocation ? "active" : ""
                        }
                      >
                        <Input
                          className="checkClass"
                          type="checkbox"
                          checked={item.location === selectedLocation}
                        />
                        <AddressIcon src={addIcon} />
                        <CheckboxDistance>
                          {Math.round(item.distance * 100) / 100} km
                        </CheckboxDistance>
                        <CheckboxLabel>{item.name}</CheckboxLabel>
                      </LocationCheckBox>
                    )
                  );
                })}
                {storesVisibilityCount <= locationData.length && (
                  <SeeMoreStore onClick={handleShowMore}>
                    Show more stores
                  </SeeMoreStore>
                )}
              </>
            )}
            {isLocationError && (
              <Error className="error">{isLocationError}</Error>
            )}
            {errors.selectedLocation && (
              <Error className="error">{errors.selectedLocation}</Error>
            )}
            <FormGroupAppointment>
              Confirm your appointment date
            </FormGroupAppointment>
            <FormTextLbel>Appointment date</FormTextLbel>
            <AppointmentWrapper className="calanderWrapper">
              <DatePicker
                selected={appointmentDate}
                onChange={onDateChangeHandler}
                dropdownMode="select"
                placeholderText="dd/mm/yyyy"
                dateFormat="dd/MM/yyyy"
                autoComplete="off"
                minDate={new Date()}
                value={appointmentDate}
                id="appointmentDate"
                name="appointmentDate"
                className={errors.appointmentDate ? "error" : ""}
              />
              <span className="calenderIcon">
                <img src={calender} alt="calender" />
              </span>
              {errors.appointmentDate && (
                <Error className="error">{errors.appointmentDate}</Error>
              )}
            </AppointmentWrapper>
            <FormGroup>Individual having piercing performed</FormGroup>
            <FormDetails>
              <FormLabel>
                Name of person piercing is being performed on
              </FormLabel>
              <InputText
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(event) => handleInputChange(event)}
                placeholder="Enter your full name..."
                className={errors.name ? "error" : ""}
              />
              {errors.name && <Error className="error">{errors.name}</Error>}
            </FormDetails>
            <DateOfBirthMain>
              <DateOfBirthSectionLeft>
                <DateOfBirthFlex>
                  <FormLabel>Date of Birth</FormLabel>
                  <DateWrapper className="calanderWrapper birthCalender">
                    <DatePicker
                      selected={dateOfBirth}
                      onChange={onDateOfBirthChangeHandler}
                      dropdownMode="select"
                      showMonthDropdown
                      showYearDropdown
                      placeholderText="dd/mm/yyyy"
                      dateFormat="dd/MM/yyyy"
                      maxDate={new Date()}
                      autoComplete="off"
                      value={dateOfBirth}
                      id="dateOfBirth"
                      name="dateOfBirth"
                      className={
                        errors.dateOfBirth &&
                        errors.dateOfBirth !== "" &&
                        errors.dateOfBirth !== true
                          ? "error"
                          : ""
                      }
                    />

                    {errors.dateOfBirth === true && isWesternAustralia ? (
                      <CircleFlex>
                        <Circle>
                          <ExclamationMark>!</ExclamationMark>
                        </Circle>
                      </CircleFlex>
                    ) : (
                      <span className="calenderIcon">
                        <img src={calender} alt="calender" />
                      </span>
                    )}
                    {errors.dateOfBirth !== "" &&
                      errors.dateOfBirth !== true &&
                      errors.dateOfBirth !== undefined && (
                        <Error className="error">{errors.dateOfBirth}</Error>
                      )}
                  </DateWrapper>
                </DateOfBirthFlex>
                {errors.dateOfBirth === true && isWesternAustralia && (
                  <DateOfBirthSectionRight>
                    Clients under the age of 16 for ear piercings and 18 for all
                    other piercings require parent/guardian consent in the state
                    of Western Australia
                  </DateOfBirthSectionRight>
                )}
              </DateOfBirthSectionLeft>
            </DateOfBirthMain>

            <YourPiercing>Your piercing</YourPiercing>
            <Para>
              What piercings will you be getting?
              <MandatoryFieldIndicator />
            </Para>
            <CheckboxGroup
              options={piercingOptions}
              onSelections={handlePiercingBodyPartsSelection}
            />
            {errors.piercingBodyParts && (
              <Error className="error">{errors.piercingBodyParts}</Error>
            )}
            {isNipplePiercing && (
              <>
                <Para>
                  Do you consent to a body piercer of the opposite sex
                  performing your nipple piercing proceedure?{" "}
                  <MandatoryFieldIndicator />
                </Para>
                <RadioGroup
                  options={radioOptions}
                  selectedOption={consentToOppositSexPiercer}
                  onOptionChange={handleConsentToOppositSexPiercerSelection}
                />
                {errors.consentToOppositSexPiercer && (
                  <Error className="error">
                    {errors.consentToOppositSexPiercer}
                  </Error>
                )}
              </>
            )}
            {consentToOppositSexPiercer === 2 && isNipplePiercing && (
              <>
                <Para>
                  Do you require an extra employee of your gender or a support
                  person in the room? <MandatoryFieldIndicator />
                </Para>
                <RadioGroup
                  options={radioOptions}
                  selectedOption={requiredSupportPerson}
                  onOptionChange={handleRequiredSupportPersonSelection}
                />

                {errors.requiredSupportPerson && (
                  <Error className="error">
                    {errors.requiredSupportPerson}
                  </Error>
                )}
              </>
            )}

            <FormGroup>
              {isParentalConsent
                ? "Parent/Guardian details"
                : "Contact Details"}
            </FormGroup>
            {isParentalConsent && (
              <FormDetails>
                <FormLabel>Name</FormLabel>
                <InputText
                  type="text"
                  id="parentName"
                  name="parentName"
                  value={parentName}
                  onChange={(event) => handleInputChange(event)}
                  placeholder="Enter full name..."
                  className={errors.parentName ? "error" : ""}
                />
                {errors.parentName && (
                  <Error className="error">{errors.parentName}</Error>
                )}
              </FormDetails>
            )}
            <FormDetails>
              <FormLabel>Email Address</FormLabel>

              <InputText
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(event) => handleInputChange(event)}
                placeholder="Enter your email address..."
                className={errors.email ? "error" : ""}
              />
              {errors.email && <Error className="error">{errors.email}</Error>}
            </FormDetails>
            <FormDetails>
              <FormLabel>Mobile Number</FormLabel>

              <InputText
                formNoValidate
                inputMode="numeric"
                pattern="\d*"
                id="mobileNumber"
                name="mobileNumber"
                value={mobileNumber}
                type="tel"
                onChange={(event) => handleInputChange(event)}
                placeholder="Enter your mobile number..."
                className={errors.mobileNumber ? "error" : ""}
              />

              {errors.mobileNumber && (
                <Error className="error">{errors.mobileNumber}</Error>
              )}
            </FormDetails>
            <FormDetails>
              <FormLabel>Residential Address</FormLabel>
              <InputText
                type="text"
                id="residentialAddress"
                name="residentialAddress"
                value={residentialAddress}
                onChange={(event) => handleInputChange(event)}
                placeholder="Enter your address..."
                className={errors.residentialAddress ? "error" : ""}
              />
              {errors.residentialAddress && (
                <Error className="error">{errors.residentialAddress}</Error>
              )}
            </FormDetails>

            <FormGroup>Medical conditions</FormGroup>
            <ParaMedical>
              Are you prone to fainting or have fears of medical procedures?
              <MandatoryFieldIndicator />
            </ParaMedical>
            <RadioGroup
              options={radioOptions}
              selectedOption={fearOfMedicalProcedures}
              onOptionChange={handleFearOfMedicalProceduresSelection}
            />
            {errors.fearOfMedicalProcedures && (
              <Error className="error">{errors.fearOfMedicalProcedures}</Error>
            )}
            <Para>
              Are you currently taking prescription medication?
              <MandatoryFieldIndicator />
            </Para>
            <RadioGroup
              options={radioOptions}
              selectedOption={takingMedication}
              onOptionChange={handleTakingMedicationSelection}
            />
            {errors.takingMedication && (
              <Error className="error">{errors.takingMedication}</Error>
            )}
            {takingMedication === 2 && (
              <FormDetailsMedication>
                <FormLabel>Medications</FormLabel>
                <InputText
                  type="text"
                  id="medications"
                  name="medications"
                  value={medications}
                  onChange={(event) => handleInputChange(event)}
                  placeholder="Please list your medications..."
                />
                {errors.medications && (
                  <Error className="error">{errors.medications}</Error>
                )}
              </FormDetailsMedication>
            )}
            <Para>
              Are you currently taking non-prescribed drugs?
              <MandatoryFieldIndicator />
            </Para>
            <RadioGroup
              options={radioOptions}
              selectedOption={takingNonPrescribedDrugs}
              onOptionChange={handleTakingNonPrescribedDrugsSelection}
            />
            {errors.takingNonPrescribedDrugs && (
              <Error className="error">{errors.takingNonPrescribedDrugs}</Error>
            )}
            {takingNonPrescribedDrugs === 2 && (
              <FormDetailsMedication>
                <FormLabel>Non-prescribed drugs</FormLabel>
                <InputText
                  type="text"
                  id="nonPrescribedDrugs"
                  name="nonPrescribedDrugs"
                  value={nonPrescribedDrugs}
                  onChange={(event) => handleInputChange(event)}
                  placeholder="Please list your non-prescribed drugs..."
                />
                {errors.nonPrescribedDrugs && (
                  <Error className="error">{errors.nonPrescribedDrugs}</Error>
                )}
              </FormDetailsMedication>
            )}
            <Para>Do you have any of the following conditions?</Para>
            <CheckboxGroup
              options={diseaseOptions}
              onSelections={handleCurrentDiseasesSelection}
            />
            <FormGroup>Consent</FormGroup>
            <Para>
              Do you consent to Hairhouse taking photographs of your piercing
              and to Hairhouse using/publishing any such photograph?
              <MandatoryFieldIndicator />
            </Para>
            <PhotographsConsent>
              The photographs are always your own, Hairhouse would like to use
              them in marketing, promotional, and/or educational materials with
              your consent.
            </PhotographsConsent>
            <RadioGroup
              options={radioOptions}
              selectedOption={phographyConsent}
              onOptionChange={handlePhographyConsentSelection}
            />
            {errors.phographyConsent && (
              <Error className="error">{errors.phographyConsent}</Error>
            )}
            <FormGroup>Pre-piercing checklist</FormGroup>
            <ParaMedical>
              Things to remember for your appointment. Tick all to confirm.
              <MandatoryFieldIndicator />
            </ParaMedical>

            <CheckboxGroup
              options={activeChecklist}
              direction="column"
              onSelections={handleChecklistSelection}
            />
            {errors.checklist && (
              <Error className="error">{errors.checklist}</Error>
            )}
            <RecaptchaSection>
              <ReCaptcha
                sitekey={ReCAPTCHA_SITE_KEY}
                onChange={onChangeFunction}
                ref={captchaRef}
                required
              />
            </RecaptchaSection>
            {errors.recaptchaValue && (
              <Error className="error">{errors.recaptchaValue}</Error>
            )}
            <FormButton disabled={isLoading} type="submit">
              {isLoading ? <span className="loader"></span> : "SUBMIT"}
            </FormButton>
          </FormContainerChild>
        </form>
      </main>
      {apiError && (
        <ErrorNotification message={apiError} onClose={handleErrorClose} />
      )}
      <Footer />
    </>
  );
};

export default CheckboxForm;
