import React from "react";
import {
  FooterContainer,
  MainContainer,
  FooterDiv,
  FooterLogo,
  EmailDiv,
  FooterYear,
} from "../../styles/form";
import footerlogo from "./../../image/HairhouseLogo.png";

const Footer = () => {
  return (
    <FooterContainer>
      <MainContainer>
        <FooterLogo src={footerlogo} />

        <FooterDiv>
          Need help?
          <EmailDiv>
            Email: <FooterDiv> enquiries@hairhouse.com.au</FooterDiv>
          </EmailDiv>
        </FooterDiv>

        <FooterYear>© 2023 HAIRHOUSE</FooterYear>
      </MainContainer>
    </FooterContainer>
  );
};

export default Footer;
