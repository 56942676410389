import { ERROR_MESSAGE } from "./constant";

export const getStoresList = async ({ lat, lng }) => {
  const response = await fetch(
    // `https://ap-southeast-2.aws.data.mongodb-api.com/app/netstore-pro-hairhouse-najxb/endpoint/storelocator?secret=majxvlloresplaueiqysfeybdsibapwp&lat=${lat}&lng=${lng}`,
    `https://storelocator-m2bn4ys5sa-uc.a.run.app/?lat=${lat}&lng=${lng}`,
    {
      method: "GET",
      headers: {
        contentType: "application/json",
        accessControlAllowOrigin: "*",
      },
      dataTye: "json",
    }
  ).catch((err) => {
    console.log("err:", err);
  });

  const data = await response.json();
  return data;
};

export const requestFormSubmit = async (body) => {
  const options = {
    method: "POST",
    url: "https://submitpiercingconsent-m2bn4ys5sa-uc.a.run.app",
    headers: {
      "access-control-allow-origin": "*",
      contentType: "application/json",
      mode: "no-cors",
    },
    body: JSON.stringify({
      functionName: "createCustomerPiercingConsentWeb",
      data: body,
    }),
  };
  try {
    const response = await fetch(options.url, {
      method: options.method,
      headers: options.headers,
      body: options.body,
    });
    const data = await response.json();
    if (data.type && data.type === "error.SuiteScriptError") {
      throw Error(ERROR_MESSAGE.API_ERROR);
    } else {
      return data;
    }
  } catch {
    throw Error(ERROR_MESSAGE.API_ERROR);
  }
};
