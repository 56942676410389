import styled, { keyframes } from "styled-components";

// fade in fade out animation
const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const ErrorWrapper = styled.div`
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 600px;
  margin: 0 auto;
  color: #ffffff;
  display: flex;
  position: fixed;
  font-size: 14px;
  background-color: #dd0000;
  justify-content: space-between;
  opacity: 0;

  &.active {
    animation: ${fadeIn} 0.3s ease-in-out forwards;
  }

  p {
    margin: 0;
    padding: 18px;
  }

  button.close-cta {
    width: 48px;
    padding: 0;
    border: none;
    color: #ffffff;
    cursor: pointer;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    background-color: #bb0000;
  }
`;
